import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function CollectionsFnd() {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCollections() {
      try {
        setLoading(true);
        const response = await fetch('https://api.thegraph.com/subgraphs/name/f8n/fnd', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `
              query MyQuery {
                collectionContracts(
                  where: {
                    creator_: { id: "0x522d1cb57efc5f00eca49742f25df0392df863fd" }
                    dateSelfDestructed: null
                  }
                ) {
                  nftContract {
                    name
                    id
                    symbol
                    nfts(where: {owner_not: "0x0000000000000000000000000000000000000000"}) {
                      id
                      dateMinted
                      tokenIPFSPath
                      tokenId
                    }
                  }
                  id
                  dateSelfDestructed
                }
              }
            `,
          }),
        });

        const { data } = await response.json();
        setCollections(data.collectionContracts);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchCollections();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <section className='section section--collections'>
      <div className='container'>
        <div className='collections'>
          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3 my-4'>
            {collections.map((collection) => (
              <div key={collection.id} className='col'>
                <Link
                  className='collection-item p-3 h-100 justify-content-center card'
                  to={`/foundation/collection?contractId=${collection.id}`}
                  key={collection.id}
                >
                  <LazyLoadImage
                    effect='blur'
                    className='collection-logo'
                    src={'/foundation/' + collection.nftContract.name + '/cover.jpg'}
                    alt={collection.name}
                    loading="lazy"
                  />
                  <h2 className='collection-title'>{collection.nftContract.name}</h2>
                  <p className='collection-items'>{collection.nftContract.nfts.length} items</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

    </section>
  );
}

export default CollectionsFnd;
