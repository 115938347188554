import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function TokenComponentEth() {
  const location = useLocation();
  const [nftData, setNftData] = useState(null);
  const [contractName, setContractName] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [contractSymbol, setContractSymbol] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);


  useEffect(() => {
    async function fetchNFTData() {
      try {
        setLoading(true);

        const searchParams = new URLSearchParams(location.search);
        const contractId = searchParams.get('contract');
        const tokenId = searchParams.get('token_id');

        const response = await fetch('https://api.thegraph.com/subgraphs/name/f8n/fnd', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `
              query MyQuery($contractAddress: String!, $tokenId: String!) {
                nftContract(id: $contractAddress) {
                  id
                  name
                  symbol
                  nfts(where: {tokenId: $tokenId}) {
                    tokenId
                    tokenIPFSPath
                    dateMinted
                    lastSalePriceInETH
                    mostRecentAuction {
                      reservePriceInETH
                      status
                    }
                    mostRecentBuyNow {
                      amountInETH
                      status
                    }
                    nftHistory(orderBy: date, orderDirection: desc) {
                      amountInETH
                      date
                      event
                      id
                    }
                    ownedOrListedBy {
                      id
                    }
                  }
                }
              }
            `,
            variables: {
              contractAddress: contractId,
              tokenId: tokenId
            }
          }),
        });

        const { data } = await response.json();
        setNftData(data?.nftContract?.nfts[0]);
        setContractName(data?.nftContract?.name);
        setContractId(data?.nftContract?.id);
        setContractSymbol(data?.nftContract?.symbol);

        const metadataResponse = await fetch(`https://fnd-collections4.mypinata.cloud/ipfs/${data.nftContract.nfts[0].tokenIPFSPath}`);
        const metadataText = await metadataResponse.text();
        const metadata = JSON.parse(metadataText);
        setMetadata(metadata);

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchNFTData();
  }, [location.search]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!nftData) return null;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const dateMinted = formatTimestamp(nftData.dateMinted);
  const fileExtension = metadata && metadata.image ? metadata.image.split('.').pop().toLowerCase() : null;


  let foundationLink = `https://foundation.app/@roccano/${contractSymbol}/${nftData.tokenId}`;
  if (nftData.ownedOrListedBy?.id.toLowerCase() === 'meta') {
    foundationLink = foundationLink.replace('/meta/', '/metafisica/');
  }


  return (
    <>
      <Helmet>
        <title>{metadata.name}</title>
      </Helmet>
      <section className='token-detail'>
        <div className='container'>
          <div className="token-detail-container py-4">
            <div className="wrap-token-detail">
              <div className='img-token-detail'>
                {fileExtension === 'gif' && (
                  <>
                    {isImageFullScreen ? (
                      <div
                        className={`fullscreen-image-overlay ${isImageFullScreen ? "visible" : ""}`}
                        onClick={() => setIsImageFullScreen(false)}
                      >
                        <div className="close-fullscreen-image">Close</div>
                        <img
                          src={`/foundation/${contractName}/${nftData.tokenId}.gif`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          className='token-img-fullscreen'
                        />
                      </div>
                    ) : (
                      <div onClick={() => setIsImageFullScreen(true)} className="open-fullscreen-image">
                        <LazyLoadImage
                          effect='blur'
                          className='token-img'
                          src={`/foundation/${contractName}/${nftData.tokenId}.gif`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          loading="lazy"
                        />
                      </div>
                    )}
                  </>
                )}
                {fileExtension === 'jpg' && (
                  <>
                    {isImageFullScreen ? (
                      <div
                        className={`fullscreen-image-overlay ${isImageFullScreen ? "visible" : ""}`}
                        onClick={() => setIsImageFullScreen(false)}
                      >
                        <div className="close-fullscreen-image">Close</div>
                        <img
                          src={`/foundation/${contractName}/${nftData.tokenId}.jpg`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          className='token-img-fullscreen'
                        />
                      </div>
                    ) : (
                      <div onClick={() => setIsImageFullScreen(true)} className="open-fullscreen-image">
                        <LazyLoadImage
                          effect='blur'
                          className='token-img'
                          src={`/foundation/${contractName}/${nftData.tokenId}.jpg`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          loading="lazy"
                        />
                      </div>
                    )}
                  </>
                )}
                {fileExtension === 'png' && (
                  <>
                    {isImageFullScreen ? (
                      <div
                        className={`fullscreen-image-overlay ${isImageFullScreen ? "visible" : ""}`}
                        onClick={() => setIsImageFullScreen(false)}
                      >
                        <div className="close-fullscreen-image">Close</div>
                        <img
                          src={`/foundation/${contractName}/${nftData.tokenId}.png`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          className='token-img-fullscreen'
                        />
                      </div>
                    ) : (
                      <div onClick={() => setIsImageFullScreen(true)} className="open-fullscreen-image">
                        <LazyLoadImage
                          effect='blur'
                          className='token-img'
                          src={`/foundation/${contractName}/${nftData.tokenId}.png`}
                          alt={metadata.name} // Assuming there's a name property in metadata
                          loading="lazy"
                        />
                      </div>
                    )}
                  </>
                )}
                {fileExtension === 'mp4' && (
                  <video autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true} className='token-video' poster={`/foundation/${contractName}/${nftData.tokenId}.jpg`}>
                    <source src={`/foundation/${contractName}/${nftData.tokenId}.mp4`} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                )}
                {fileExtension !== 'gif' && fileExtension !== 'jpg' && fileExtension !== 'png' && fileExtension !== 'mp4' && (
                  <p>Unsupported file format</p>
                )}
              </div>
              <div className='info-token-detail'>
                <Link to={`/foundation/collection?contractId=${contractId}`}>
                  <h5 className="token-collection">{contractName}</h5>
                </Link>
                <h2 className='token-name fw-bold'>
                  {metadata.name}
                </h2>
                <p className='token-description pt-4'>
                  {metadata.description}
                </p>
                <div className='row row-cols-1 row-cols-sm-2 g-2 pt-2 pb-4'>
                  <div className='col'>
                    <div className='card text-center p-2 h-100 justify-content-center'>

                      <div className='fw-bold fs-5'>
                        {nftData.lastSalePriceInETH ? (
                          <div>
                            <div className='text-body-secondary fs-6'>Last sold:</div>
                            <div className='fw-bold fs-5'>{nftData.lastSalePriceInETH} ETH</div>
                          </div>
                        ) : nftData.mostRecentAuction && nftData.mostRecentAuction.status === 'Open' ? (
                          <div>
                            <div className='text-body-secondary fs-6'>Reserve price:</div>
                            <div className='fw-bold fs-5'>{nftData.mostRecentAuction.reservePriceInETH} ETH</div>
                          </div>
                        ) : nftData.mostRecentBuyNow && nftData.mostRecentBuyNow.status === 'Open' ? (
                          <div>
                            <div className='text-body-secondary fs-6'>Buy now:</div>
                            <div className='fw-bold fs-5'>{nftData.mostRecentBuyNow.amountInETH} ETH</div>
                          </div>
                        ) : (
                          <div className='text-muted'>-</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to={foundationLink}
                  className="token-cta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Foundation
                </Link>
              </div>
            </div>
            <Tabs className='pt-5'>
              <TabList>
                <Tab>Properties</Tab>
                <Tab>Owners</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 py-2'>
                  <div className='col'>
                    <div className='card d-flex justify-content-center text-center p-4 h-100'>
                      <div className='fs-6'>Token id</div>
                      <div className='fw-bold fs-5'>{nftData.tokenId}</div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='card d-flex justify-content-center text-center p-4 h-100'>
                      <div className='fs-6'>Minted</div>
                      <div className='fw-bold fs-5'>{dateMinted}</div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='card d-flex justify-content-center text-center p-4 h-100'>
                      <Link
                        to={`https://etherscan.io/nft/${nftData.id}/${nftData.tokenId}`}
                        className="token-cta fs-5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contract
                      </Link>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='card d-flex justify-content-center text-center p-4 h-100'>
                      <Link
                        to={`https://fnd-collections4.mypinata.cloud/ipfs/${nftData.tokenIPFSPath}`}
                        className="token-cta fs-5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Metadata
                      </Link>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 py-2'>
                  <div className='col'>
                    <div className='card d-flex justify-content-center text-center p-4 h-100 fs-5'>
                      <div>
                        <Link to={`https://foundation.app/${nftData.ownedOrListedBy?.id}`} target='_blank'>{nftData.ownedOrListedBy?.id.substring(0, 5)}...{nftData.ownedOrListedBy?.id.slice(-5)}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope="col">Last activity</th>
                        <th scope="col">Event</th>
                        <th scope="col">Unit price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nftData.nftHistory.map((historyItem) => (
                        <tr key={historyItem.id}>
                          <td>{formatTimestamp(historyItem.date)}</td>
                          <td>{historyItem.event}</td>
                          <td>{historyItem.amountInETH}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default TokenComponentEth;
