import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function CollectionFnd() {
  const location = useLocation();
  const [nftContracts, setNftContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [numOwners, setNumOwners] = useState(0);
  const [floorPrice, setFloorPrice] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contractId = searchParams.get('contractId');

    async function fetchNftContracts() {
      try {
        setLoading(true);

        const response = await fetch('https://api.thegraph.com/subgraphs/name/f8n/fnd', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `
              query MyQuery {
                nftContract(id: "${contractId}") {
                  name
                  id
                  nfts(where: {owner_not: "0x0000000000000000000000000000000000000000"}) {
                    tokenId
                    tokenIPFSPath
                    id
                    dateMinted
                    lastSalePriceInETH
                    ownedOrListedBy{
                      id
                    }
                    mostRecentAuction {
                      reservePriceInETH
                      status
                    }
                    mostRecentBuyNow {
                      amountInETH
                      status
                    }
                  }
                }
              }
            `
          }),
          timeout: 1000,
        });

        const { data } = await response.json();
        setNftContracts(data.nftContract ? [data.nftContract] : []);
        const owners = new Set(data.nftContract?.nfts.map((nft) => nft.ownedOrListedBy.id));
        setNumOwners(owners.size);
        const nfts = data.nftContract?.nfts.filter(nft => nft.mostRecentAuction && nft.mostRecentAuction.status === 'Open');
        const reservePrices = nfts.map(nft => parseFloat(nft.mostRecentAuction.reservePriceInETH));
        const minReservePrice = Math.min(...reservePrices);
        setFloorPrice(isFinite(minReservePrice) ? minReservePrice : null);


      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    if (contractId) {
      fetchNftContracts();
    }
  }, [location.search]);

  useEffect(() => {
    async function fetchMetadata(contract) {
      try {
        setLoading(true);
        const metadataPromises = contract.nfts.map(async (nft) => {
          const response = await fetch(`https://fnd-collections4.mypinata.cloud/ipfs/${nft.tokenIPFSPath}`);
          const text = await response.text();
          return JSON.parse(text);
        });
        const metadataArray = await Promise.all(metadataPromises);

        const nftsWithMetadata = contract.nfts.map((nft, index) => ({
          ...nft,
          metadata: metadataArray[index]
        }));
        setNftContracts([{
          ...contract,
          nfts: nftsWithMetadata
        }]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    if (nftContracts.length > 0 && !nftContracts[0].nfts.every(nft => nft.metadata)) {
      fetchMetadata(nftContracts[0]);
    }
  }, [nftContracts]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;


  return (
    <>
      <Helmet>
        {nftContracts.map(contract => (
          <title key={contract.name}>{contract.name}</title>
        ))}
      </Helmet>
      <section className='section section--collection-listing'>
        {nftContracts.map((contract) => (
          <div key={contract.id}>
            <section className='section section--collection-detail'>
              <div className='collection-detail'>
                <div className='wrap-collection-detail'>
                  <LazyLoadImage
                    effect='blur'
                    className='collection-logo'
                    src={`/foundation/${contract.name}/cover.jpg`}
                    alt={contract.name}
                    loading="lazy"
                  />
                  <h1 className="collection-name fw-bold">{contract.name}</h1>
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 g-3 my-4 justify-content-center">
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Collection of</span>
                        <span className="fw-bold fs-5">{contract.nfts.length}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Owned by</span>
                        <span className="fw-bold fs-5">{numOwners}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Floor Price</span>
                        <span className="fw-bold fs-5">{floorPrice !== null ? `${floorPrice} ETH` : '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className='section section--collection-listing'>
              <div className='container'>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3">
                  {contract.nfts.map((nft) => {
                    // Definisci fileExtension qui per calcolare l'estensione del file per ogni nft
                    const fileExtension = nft.metadata && nft.metadata.image ? nft.metadata.image.split('.').pop().toLowerCase() : null;

                    return (
                      <div className='col' key={nft.id}>
                        <div className='card d-flex justify-content-center'>
                          <Link
                            to={`/foundation/token?contract=${contract.id}&token_id=${nft.tokenId}`}
                            state={{ nftData: nft }}
                            className='d-flex'
                          >
                            {fileExtension === 'jpg' && (
                              <LazyLoadImage
                                effect='blur'
                                className='token-img'
                                src={`/foundation/${contract.name}/${nft.tokenId}.jpg`}
                                alt={nft.metadata.name} // Assuming there's a name property in metadata
                                loading="lazy"
                              />
                            )}
                            {fileExtension === 'png' && (
                              <LazyLoadImage
                                effect='blur'
                                className='token-img'
                                src={`/foundation/${contract.name}/${nft.tokenId}.png`}
                                alt={nft.metadata.name} // Assuming there's a name property in metadata
                                loading="lazy"
                              />
                            )}
                            {fileExtension === 'gif' && (
                              <LazyLoadImage
                                effect='blur'
                                className='token-img'
                                src={`/foundation/${contract.name}/${nft.tokenId}.gif`}
                                alt={nft.metadata.name} // Assuming there's a name property in metadata
                                loading="lazy"
                              />
                            )}
                            {fileExtension === 'mp4' && (
                              <video autoPlay={true}
                                muted={true}
                                loop={true}
                                playsInline={true} className='token-video' poster={`/foundation/${contract.name}/${nft.tokenId}.jpg`}>
                                <source src={`/foundation/${contract.name}/${nft.tokenId}.mp4`} type='video/mp4' />
                                Your browser does not support the video tag.
                              </video>
                            )}
                            {!fileExtension && <span>-</span>} {/* Se non c'è fileExtension, mostra un segnaposto */}
                          </Link>
                          <div className='card-body'>
                            <div className='card-title fs-4 fw-bold'>
                              <Link
                                to={`/foundation/token?contract=${contract.id}&token_id=${nft.tokenId}`}
                                state={{ nftData: nft }}
                              >
                                {/* Display metadata.name if available */}
                                {nft.metadata && nft.metadata.name ? nft.metadata.name : '-'}
                              </Link>
                            </div>
                            <div className='d-flex justify-content-between py-1 fs-6'>
                              <div className='d-flex flex-column'>
                                <div>
                                  {nft.lastSalePriceInETH ? (
                                    <div>
                                      <div className='text-body-secondary fs-6'>Last sold:</div>
                                      <div className='fw-bold fs-5'>{nft.lastSalePriceInETH} ETH</div>
                                    </div>
                                  ) : nft.mostRecentAuction && nft.mostRecentAuction.status === 'Open' ? (
                                    <div>
                                      <div className='text-body-secondary'>Reserve price:</div>
                                      <div className='fw-bold fs-6'>{nft.mostRecentAuction.reservePriceInETH} ETH</div>
                                    </div>
                                  ) : nft.mostRecentBuyNow && nft.mostRecentBuyNow.status === 'Open' ? (
                                    <div>
                                      <div className='text-body-secondary'>Buy now:</div>
                                      <div className='fw-bold fs-6'>{nft.mostRecentBuyNow.amountInETH} ETH</div>
                                    </div>
                                  ) : (
                                    <div className='text-muted'>-</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

              </div>
            </section>
          </div>
        ))}
      </section>
    </>
  );

}

export default CollectionFnd;
