import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CollectionsObjkt from '../components/CollectionsObjkt';
import CollectionsFnd from '../components/CollectionsFnd';
import CollectionsZora from '../components/CollectionsZora';
import './Collections.scss';

const CollectionsPage = () => {
  const [selectedOption, setSelectedOption] = useState('Objkt');
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Roccano</title>
      </Helmet>
      <div className="main">
        <div className='container'>
          <div className='radio-group mt-4'>
            <label>
              <input
                type="radio"
                value="Objkt"
                checked={selectedOption === 'Objkt'}
                onChange={handleOptionChange}
              />
              <span className="radio-label">Objkt</span>
            </label>
            <div className='divider'></div>
            {/* <label>
              <input
                type="radio"
                value="Foundation"
                checked={selectedOption === 'Foundation'}
                onChange={handleOptionChange}
              />
              <span className="radio-label">Foundation</span>
            </label>
            <div className='divider'></div> */}
            <label>
              <input
                type="radio"
                value="Zora"
                checked={selectedOption === 'Zora'}
                onChange={handleOptionChange}
              />
              <span className="radio-label">Zora</span>
            </label>
          </div>
          {selectedOption === 'Objkt' ? (
            <>
              <CollectionsObjkt />
            </>
          ) : null}
          {selectedOption === 'Foundation' ? <CollectionsFnd /> : null}
          {selectedOption === 'Zora' ? <CollectionsZora /> : null}
        </div>
      </div>
    </>
  );
};

export default CollectionsPage;
